import React, { useState } from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { CvBtn } from "components/hashtag/cv-btn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { DefaultHeader } from "components/headers/default-header"
import { Service } from "components/service"
import { SampleCardsA } from "components/hashtag/sample-cards-a"
import { SampleCardsB } from "components/hashtag/sample-cards-b"

const HashtagCampaign = ({ location }) => {
  /** sampleタブ表示切り替え */
  const [isShowSampleA, setIsShowSampleA] = useState(true)
  const [isShowSampleB, setIsShowSampleB] = useState(false)
  const activeStyle = {
    borderBottom: "solid 4px #4bc2bb",
    color: "#4bc2bb",
  }
  const inActiveStyle = {
    borderBottom: "solid 1px #ededed",
    color: "#ededed",
  }

  const onClickShowSampleA = () => {
    setIsShowSampleA(true)
    setIsShowSampleB(false)
  }
  const onClickShowSampleB = () => {
    setIsShowSampleA(false)
    setIsShowSampleB(true)
  }
  /** UGC説明 */
  const [isShowUgc, setShouldShowUgc] = useState(false)
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="UGC collection and posting on Twitter | Hashtag Campaign"
          pagedesc="Automatically collects &amp; displays hashtagged posts from Twitter and Instagram. Learn about PARKLoT's services, which are ideal for hashtag campaigns, including the process of publication, extensive features, and competitive rates."
          pagepath={location.pathname}
        />
        <main className="hashtag">
          <section className="hs__fv">
            <div className="hs__fv-container">
              <div className="hs__fv-text-inner">
                <div className="hs__fv-catch-container">
                  <p className="hs__fv-catch">Amazing price</p>
                  <p className="hs__fv-catch">Minimum 1 day</p>
                </div>
                <h1 className="hs__fv-h1">
                  <span>
                    Collect and post UGC on your existing site without hassle!
                  </span>
                  <StaticImage
                    layout="constrained"
                    alt="Collect and post UGC on your existing site without hassle!"
                    src="../../../images/product/hs-h1.png"
                  />
                </h1>
                <p className="hs__fv-lead">
                  Automatically collects &amp; displays hashtagged posts from
                  Twitter and Instagram. Great for hashtag campaigns!
                </p>
              </div>
              <a
                className="hs__fv-cv-btn"
                href="https://hub.park-lot.com/hashtag-document-download"
                target="_blank"
                rel="noreferrer noopener"
              >
                Download our materials (free)
              </a>
              <div className="hs__fv-img-container">
                <div className="hs__fv-img">
                  <StaticImage
                    layout="constrained"
                    alt="Collect and post UGC on your existing site without hassle!"
                    src="../../../images/product/hs-fv.png"
                  />
                </div>
                <div className="hs__fv-ugc-btn-container">
                  <button
                    className="hs__fv-ugc"
                    type="button"
                    onClick={() => setShouldShowUgc(prevState => !prevState)}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
                    What is UGC?
                  </button>
                  {isShowUgc && (
                    <div className="hs__fv-ugc-detail-container">
                      UGC (User Generated Content) refers to photo and video
                      content generated by ordinary users and posted on social
                      networking sites such as Twitter and Instagram.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="hs__flow">
            <div className="hs__container">
              <div className="hs__heading">
                <h2 className="hs__title">Flow</h2>
                <p className="hs__subtitle">Steps to Publication</p>
                <p className="hs__lead">
                  No cumbersome collection and posting process.&nbsp;
                  <br />
                  UGC can be implemented easily and without hassle.
                </p>
              </div>
              <div className="hs__flow-container">
                <div className="hs__flow-card">
                  <StaticImage
                    layout="constrained"
                    alt="Automatic collection of posts"
                    src="../../../images/product/hs-flow_01.jpg"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">
                      Automatic collection of posts
                    </h3>
                    <p>
                      Automatically aggregate posts based on hashtags. Video,
                      text, and images can all be collected. (Twitter and
                      Instagram are supported)
                    </p>
                  </div>
                </div>
                <div className="hs__flow-card">
                  <StaticImage
                    layout="constrained"
                    alt="Management of collected posts"
                    src="../../../images/product/hs-flow-02-re.png"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">
                      Management of collected posts
                    </h3>
                    <p>
                      Collected content can be viewed from the management
                      screen. In addition, the display function of each post can
                      be easily set to show or hide.
                    </p>
                  </div>
                </div>
                <div className="hs__flow-card">
                  <StaticImage
                    layout="constrained"
                    alt="Embedding Posts"
                    src="../../../images/product/hs-flow-03.png"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">Embedding Posts</h3>
                    <p>
                      Easily embed posts on your own website or campaign LP.
                      Real-time postings by users can be posted automatically.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hs__feature">
            <div className="hs__container">
              <div className="hs__heading">
                <h2 className="hs__title">Feature</h2>
                <p className="hs__subtitle">Feature</p>
                <p className="hs__lead">
                  It has a complete set of features that can drastically
                  cut&nbsp;
                  <br />
                  the cost of work from collection to posting.
                </p>
              </div>
              <div className="hs__feature-container">
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Hashtag collection (Twitter and Instagram compatible)"
                    src="../../../images/product/collect.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Hashtag collection&nbsp;
                    <br />
                    <span>(Twitter and Instagram compatible)</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    Collect posts with specific hashtags. Text, images, and
                    videos can also be collected. Up to four different hashtags
                    can be set.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Report Extracting"
                    src="../../../images/product/report.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Report Extracting
                    <br />
                  </h3>
                  <p className="hs__feature-card-text">
                    You can check various numbers such as impressions and
                    engagement, which are important indicators for analyzing
                    posts.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Support for embedding posts on existing sites"
                    src="../../../images/product/embed.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Support for embedding&nbsp;
                    <br />
                    posts on existing sites
                  </h3>
                  <p className="hs__feature-card-text">
                    UGC can be posted simply by embedding the tags we issue into
                    your company's website or campaign site.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Automatic real-time post collection"
                    src="../../../images/product/automatic.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Automatic real-time post collection
                  </h3>
                  <p className="hs__feature-card-text">
                    After the start of post collection, the system automatically
                    collects specific hashtags in real time.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Post display settings with on/off"
                    src="../../../images/product/choice.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Post display settings with on/off
                  </h3>
                  <p className="hs__feature-card-text">
                    You can set whether to publish or not. The basic setting is
                    off, so any inappropriate images will not be displayed.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Lottery functions"
                    src="../../../images/product/lottery.png"
                  />
                  <h3 className="hs__feature-card-title">Lottery functions</h3>
                  <p className="hs__feature-card-text">
                    Winners are automatically drawn.
                    <br />
                    You can also choose to draw at a later date or immediately.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="DM Sending"
                    src="../../../images/product/email.png"
                  />
                  <h3 className="hs__feature-card-title">DM Sending</h3>
                  <p className="hs__feature-card-text">
                    Unique gift codes and URLs can be sent as notification of
                    winning results.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Customize Sort Order"
                    src="../../../images/product/sortby.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Customize Sort Order
                    <br />
                    <span>*option</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    The order can be customized by date and time of posting,
                    number of likes received, etc.
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="Customize Design"
                    src="../../../images/product/design.png"
                  />
                  <h3 className="hs__feature-card-title">
                    Customize Design
                    <br />
                    <span>*option</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    We can also provide a design that fits your company's
                    website or campaign site.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <CvBtn lang="en-US" />
          <section className="hs__sample">
            <div className="hs__container">
              <div className="hs__heading hs__heading--sm">
                <h2 className="hs__title">Sample</h2>
                <p className="hs__subtitle">Case Studies</p>
                <p className="hs__lead">
                  Content can be displayed automatically&nbsp;
                  <br />
                  simply by embedding the code.
                </p>
              </div>
              <div className="hs__sample-button-container">
                <button
                  className="show-btn"
                  style={isShowSampleA ? activeStyle : inActiveStyle}
                  type="button"
                  onClick={onClickShowSampleA}
                >
                  Sample A
                </button>
                <button
                  className="show-btn"
                  style={isShowSampleB ? activeStyle : inActiveStyle}
                  type="button"
                  onClick={onClickShowSampleB}
                >
                  Sample B
                </button>
              </div>
              {isShowSampleA && <SampleCardsA />}
              {isShowSampleB && <SampleCardsB />}
              {/* <div className="hs__sample-display">
                <div id="pa-hashtag-item" data-id="1592183"></div>
              </div> */}
            </div>
          </section>
          {/* <section className="hs__price">
            <div className="hs__container">
              <div className="hs__heading hs__heading--sm">
                <h2 className="hs__title">Price</h2>
                <p className="hs__subtitle">Charge</p>
                <p className="hs__lead">
                  Affordable prices for&nbsp;
                  <br />
                  ongoing UGC collection.
                </p>
              </div>
              <div className="hs__price-card-container">
                <div className="hs__price-card">
                  <h3 className="hs__price-card-title">Basic Charge</h3>
                  <div className="hs__price-card-content">
                    <h4 className="hs__price-card-subtitle">Initial cost</h4>
                    <p className="hs__price-card-price">
                      80,000<span>yen</span>
                    </p>
                    <h4 className="hs__price-card-subtitle">Monthly amount</h4>
                    <p className="hs__price-card-price">
                      50,000<span>yen</span>
                    </p>
                    <h4 className="hs__price-card-subtitle">Total</h4>
                    <p className="hs__price-card-price">
                      130,000<span>yen (143,000 yen including tax)</span>
                    </p>
                  </div>
                </div>
                <div className="hs__price-card">
                  <h3 className="hs__price-card-title hs__price-card-title--grey">
                    Option
                  </h3>
                  <div className="hs__price-card-content">
                    <div className="hs__price-card-content--2col">
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          Collecting Likes
                        </h4>
                        <p className="hs__price-card-price">
                          30,000<span>yen</span>
                        </p>
                      </div>
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          Collecting Reply
                        </h4>
                        <p className="hs__price-card-price">
                          30,000<span>yen</span>
                        </p>
                      </div>
                    </div>
                    <div className="hs__price-card-content--2col">
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          Design Customization
                        </h4>
                        <p className="hs__price-card-price">
                          30,000<span>yen</span>
                        </p>
                      </div>
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          Sort Order Customization
                        </h4>
                        <p className="hs__price-card-price">
                          30,000<span>yen</span>
                        </p>
                      </div>
                    </div>
                    <h4 className="hs__price-card-subtitle">
                      Campaign LP creation agency
                    </h4>
                    <p className="hs__price-card-price">
                      100,000<span>yen~</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <CvBtn lang="en-US" />
          <Service />
        </main>
        {/* <script
          type="text/javascript"
          src="https://hashtag-campaigns.s3.ap-northeast-1.amazonaws.com/script/sample/hashtag-tweet.min.js"
        ></script> */}
      </Layout>
    </>
  )
}

export default HashtagCampaign
